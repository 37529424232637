import { createTheme } from '@mui/material/styles';

const DefaultTheme = createTheme({
  palette: {
    primary: {
      dark: '#0288D1',
      main: '#03A9F4',
      light: '#B3E5FC',
      contrastText: '#FFFFFF',
    },
    secondary: {
      dark: '#00796B',
      main: '#009688',
      light: '#B2DFDB',
      contrastText: '#FFFFFF',
    },
    accent: '#448AFF',
    text: {
      primary: '#212121',
      secondary: '#757575',
    },
    divider: '#BDBDBD',
    successful: '#4caf50',
    warn: '#e9a83f',
  },
});

export default DefaultTheme;