import { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import RoutesConfig from './RoutesConfig';

function AppRoutes(){
  return (
  <Suspense fallback={<div></div>}>
    <Routes>
      {
        RoutesConfig.map((route, index) => {
          const Element = route.element;
          return <Route key={`route-${index}`} path={route.path} element={<Element />}/>
        })
      }
    </Routes>
  </Suspense>
  );
}

export default AppRoutes;