import axios from "axios";

const client = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  headers: {
      "Content-Type": "application/json",
      // "Access-Control-Allow-Origin": "*",
  },
});

client.interceptors.request.use(async (config) => {
  const jwt = localStorage.getItem("jwt");
  if (Boolean(jwt))
      config.headers.Authorization = `Bearer ${jwt}`
  return config
})

// client.interceptors.response.use(
//   // return directly response.data
//   (response) => {
//       if (response && response.data) {
//           return response.data
//       }

//       return response
//   },
//   (error) => {
//       // Handle errors
//       console.log(error)

//       const response = error.response

//       throw { 
//           status_id: response.data.status_id,
//           error_code: response.data.error_code
//       }
//   }
// )

export default client;