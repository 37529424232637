export function downloadFromThirdParty(fileUrl, filename) {
  // const fileUrl = 'http://77.88.200.62:1880/mp3/stream_11_47_24__4_8_2023.mp3';
  // const filename = ''; // Replace with the desired filename
  fetch(fileUrl)
    .then(response => response.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .catch(error => console.error('Error downloading file:', error));
};
