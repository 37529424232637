import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from "react-redux";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';

import reportWebVitals from './reportWebVitals';

import App from './App';
import GlobalStyles from './GlobalStyles';
import { Store } from "./Redux"
import { getBrand } from './Utils';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Router>
        <GlobalStyles>
          <Provider store={Store}>
            <App />
          </Provider>
        </GlobalStyles>
      </Router>
    </LocalizationProvider>
  </React.StrictMode>
);

// Dynamically set favicon and title
document.addEventListener('DOMContentLoaded', function () {
  const brand = getBrand();

  // favicon
  const faviconLink = document.getElementById('favicon');
  const iconURL = brand?.favicon;
  if (iconURL) {
    faviconLink.href = iconURL;
  }

  // title
  const title = document.getElementById('head-title');
  const brandTitle = brand?.fullTitle;
  if (brandTitle) {
    title.innerText = brandTitle;
  }
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
