import { configureStore } from "@reduxjs/toolkit";

import authSlice from "./Slice/auth-slice.js";

const Store = configureStore({
  reducer: {
    auth: authSlice.reducer,
  }
});

export default Store;