import { ThemeProvider } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import DefaultTheme from './Theme/DefaultTheme';
import { AppRoutes } from './Routes';
import authAPI from './API/authAPI';
import { authSlice } from './Redux';
import { closeSnackbar, SnackbarProvider } from 'notistack';
import { IconButton } from '@mui/material';
import { Clear } from '@mui/icons-material';

function App() {
  const dispatch = useDispatch();

  const [meDone, setMeDone] = useState(false);
  // Check jwt and load user to redux
  useEffect(()=>{
    authAPI.getMe()
      .then((res)=>{
        dispatch(authSlice.actions.setUser(res.data));
      })
      .catch((err)=>{
        dispatch(authSlice.actions.setUser(undefined));
        localStorage.removeItem("jwt");// clear JWT at local storage
        localStorage.removeItem("userid");// clear userid at local storage
      })
      .finally(()=>{
        setMeDone(true);
      });
  }, [dispatch]);

  return (
    <ThemeProvider theme={DefaultTheme}>
      <div className="App">
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          action={(snackbarId) => (
            <IconButton onClick={() => closeSnackbar(snackbarId)}>
              <Clear/>
            </IconButton>
          )}
        >
          {meDone && <AppRoutes />}
        </SnackbarProvider>
      </div>
    </ThemeProvider>
  );
}

export default App;
