import client from "./client";

const authAPI = {
  getAllUsers: () => {
    return client.get('/users');
  },

  login: (data) => {
    // {
    //   "identifier": ".......",
    //   "password": "......."
    // }
    return client.post('/auth/local', data);
  },

  signup: (data) => {
    // {
    //   "username": "......",
    //   "email": "....@.....",
    //   "password": "......."
    // }
    return client.post('/auth/local/register', data);
  },

  logout: () => {
    return client.post('/logout');
  },

  getMe: () => {
    return client.get('/users/me');
  },

  changePassword: (data) => {
    return client.post('/auth/change-password', data);
  },

  updateInfo: (data) => {
    return client.put('/edit-acc-info', {data});
  },

  deleteFactor: () => {
    return client.delete('/2fa/factor');
  },

  createNewFactorID: () => {
    return client.get('/2fa/factor');
  },

  verifyFactor: (otp, factorId, secret) => {
    return client.post('/2fa/factor', {
      otp,
      factor_id: factorId,
      secret,
    })
  }
}

export default authAPI;