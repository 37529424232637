// import DefaultFavIcon from "./Assets/logo.png";
// import DefaultLogoMedium from "./Assets/logo.png";
import VNMapFavIcon from "./Assets/vnmap.favicon.ico.png";
import VNMapLogoMedium from "./Assets/vnmap.logo.png";

export const MAX_VOLUME = 130;

export const BRAND_BY_DOMAIN = {
  "default": {
    favicon: VNMapFavIcon,
    fullTitle: "Nền tảng truyền dẫn dữ liệu số, thông tin nguồn Phát Thanh-Truyền Hình VNMAP",
    fullTitleUp: "NỀN TẢNG TRUYỀN DẪN DỮ LIỆU SỐ, THÔNG TIN NGUỒN, PHÁT THANH-TRUYỀN HÌNH VNMAP",
    shortTitle: "VNMAP",
    shortTitleUp: "VNMAP",
    logoMedium: VNMapLogoMedium,
  },

  "truyenthanh.vnmap3d.com": {
    favicon: VNMapFavIcon,
    fullTitle: "Nền tảng truyền dẫn dữ liệu số, thông tin nguồn Phát Thanh-Truyền Hình VNMAP",
    fullTitleUp: "NỀN TẢNG TRUYỀN DẪN DỮ LIỆU SỐ, THÔNG TIN NGUỒN, PHÁT THANH-TRUYỀN HÌNH VNMAP",
    shortTitle: "VNMap",
    shortTitleUp: "VNMAP",
    logoMedium: VNMapLogoMedium,
  },

  "a.vnmap3d.com:3000": {
    favicon: VNMapFavIcon,
    fullTitle: "Nền tảng truyền dẫn dữ liệu số, thông tin nguồn Phát Thanh-Truyền Hình VNMAP",
    fullTitleUp: "NỀN TẢNG TRUYỀN DẪN DỮ LIỆU SỐ, THÔNG TIN NGUỒN, PHÁT THANH-TRUYỀN HÌNH VNMAP",
    shortTitle: "VNMap",
    shortTitleUp: "VNMAP",
    logoMedium: VNMapLogoMedium,
  },
}

export const AppLivestreamRecorderConfig = {
  recordingGain : 1, // Initial recording volume
  encoderBitRate : 64, // MP3 encoding bit rate
  streaming : true, // Data will be returned in chunks (ondataavailable callback) as it is encoded,
                    // rather than at the end as one large blob
  streamBufferSize : 1024, // Size of encoded mp3 data chunks returned by ondataavailable, if streaming is enabled
  constraints : { // Optional audio constraints, see https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia
    channelCount : 1, // Set to 2 to hint for stereo if it's available, or leave as 1 to force mono at all times
    // autoGainControl : true,
    echoCancellation : true,
    noiseSuppression : true
  },

  // Used for debugging only. Force using the older script processor instead of AudioWorklet.
  // forceScriptProcessor: true,
}

export const RamFactor = 2;